import React from "react"

import cardStyles from "./card.module.scss"

const Card = (props) => (
  <section className={cardStyles.card}>
    <div className={cardStyles.cardinfo}>
      <h3>{props.title}</h3>
      {props.subtitle && <label className={cardStyles.label}>{props.subtitle}</label>}
      <hr/>
      <p className={cardStyles.text} dangerouslySetInnerHTML={{ __html: props.text }}></p>
    </div>
  </section>
)

export default Card
