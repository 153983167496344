
import React from "react"
import { Link } from "gatsby"

import buttonStyles from "./button.module.scss"

const Button = (props) => (
    <Link to={props.link}>
    <button className={buttonStyles.button}>
        
            {props.text}
        
        <svg xmlns="http://www.w3.org/2000/svg" width="11" height="7" viewBox="0 0 11 7">
            <path fill="#FFF" fillRule="nonzero" d="M11.047 3.206L7.84 6.412V3.794H0V2.61h7.84L7.849 0z"/>
        </svg>
    </button>
    </Link>
)

export default Button
