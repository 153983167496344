import React from "react"

import Card from "../components/card"
import Heading from "../components/heading"
import Button from "../components/button"


const AreeDiAttivita = () => (
  <>

    <Heading
        title="Aree di attività"
        text="Condividiamo coi clienti le sfide del mercato globale e la necessità di superarle coniugando pragmatismo, creatività e vocazione internazionale."
    />
        <div className="row">
        <div className="col-xl-10 offset-xl-1">
            <div className="container-fluid px-0">
            <div className="row">
            <div className="col-lg-6 col-sm-12">
                <Card
                    title="Contenzioso e arbitrati"
                    text="Forti di cent’anni di tradizione, forniamo ai clienti assistenza giudiziale e disponiamo di una consolidata esperienza nel contenzioso arbitrale."
                />
                <Card
                    title="Famiglia e successioni"
                    text="Garantiamo ai nostri clienti un supporto dedicato in diritto di famiglia e successorio. <br>Ricerchiamo le soluzioni più adeguate in caso di conflitti personali e patrimoniali tra i coniugi e rappresentiamo i clienti nelle delicate fasi della separazione e del divorzio. <br>Ci occupiamo di ogni aspetto concernente la pianificazione e la tutela patrimoniale familiare nonché il passaggio generazionale d’impresa."
                />
                <Card
                    title="Privacy"
                    text="Forniamo ai nostri clienti una consulenza mirata relativamente ai principali aspetti contemplati dalla legislazione italiana ed europea in tema di tutela dei dati personali. <br>Al fine di verificare e garantire il rispetto della normativa in materia di <em>privacy</em>, analizziamo la&nbsp;gestione del flusso dei dati, provvediamo ad <em>audit</em> e <em>report</em> evidenzianti le criticità, predisponiamo e revisioniamo <em>privacy policies</em>, informative e organigrammi."
                />
            </div>
            <div className="col-lg-6 col-sm-12">
                <Card
                    title="Contratti commerciali"
                    text="Coadiuviamo i nostri clienti nella predisposizione e negoziazione di accordi commerciali nazionali e internazionali. <br>Vantiamo una comprovata professionalità nella redazione di contratti nel settore societario, delle forniture, dei servizi, della circolazione e gestione di beni immobili."
                />
                <Card
                    title="Lavoro"
                    text="Operiamo nel settore del diritto del lavoro, delle relazioni industriali e della previdenza sociale, in fase stragiudiziale e giudiziale, con approccio innovativo in un contesto in continua evoluzione. <br>Affianchiamo i clienti nella redazione di contratti di lavoro, codici disciplinari ed etici, regolamenti aziendali, <em>policy</em> di varia natura e clausole peculiari. <br>Assistiamo l’impresa nella gestione di trasferimenti individuali e collettivi di lavoratori, mutamenti di mansione, distacchi, procedure disciplinari, licenziamenti individuali e collettivi. <br>Abbiamo maturato una specifica competenza nell’amministrazione dei rapporti di lavoro nell’ambito di procedure concorsuali."
                />
                <Card
                    title="Proprietà intellettuale"
                    text="Garantiamo la tutela del patrimonio immateriale dell’impresa supportando i nostri clienti a livello nazionale e internazionale nella gestione e risoluzione delle problematiche inerenti alla proprietà intellettuale e offrendo loro consulenza strategica per la valorizzazione e lo sfruttamento dei relativi diritti.&nbsp; <br>Vantiamo comprovata esperienza nel contenzioso in tema di marchi,&nbsp;brevetti, disegni e modelli, informazioni segrete e&nbsp;<em>know-how</em>, concorrenza sleale, indicazioni e denominazioni geografiche d’origine, <em>domain names</em> e diritto d’autore. "
                />
            </div>
            </div>
    <hr className="bottom-hr"/>
            </div>
            </div>
            </div>
    <div className="button-activities">
        <Button
            text="contattaci"
            link="/contatti"
        />
    </div>



    </>
)

export default AreeDiAttivita
